.tab-hidden-area {
  max-height: 0px;

  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.tab-show-area {
  max-height: 200px;
  overflow: hidden;
  transition: 0.3s ease-in-out;

}

.menu-link{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 15px;


  i{
    height: 17px;
    width: 17px;
  }
}

.menu-icon{
  flex: 0 !important;
}
.change-password-icon {
  &:hover {
    color: #578567;
    cursor: pointer;
  }
  display: block;
  font-size: 36px;
  text-align: center;
  color: #46415d;
}
.dropdown-align {
  max-width: 300px;
  max-height: 300px;
}
.pencilIcon {
  &:hover {
    cursor: pointer;
  }
  color: #46415d;
}
.deleteIcon {
  color: #46415d;
}
.eyeIconContainer {
  position: relative;
  .eyeIcon {
    &:hover {
      cursor: pointer;
    }
    position: absolute;
    top: 12px;
    right: 10px;
  }
}
.statusbutton {
  height: calc(0.5em + 1.65rem + 2px);
  width: calc(3.5em + 1.65rem + 2px);
}
.success {
  background-color: #4caf50;
  color: white;
}

.danger {
  background-color: #f44336;
  color: white;
}
.select-handle {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.image-alignment {
  margin-top: 10px;
  margin-left: 15px;
}

.rows-alifnment {
  padding-bottom: 5px;
}

.newmodelcss {
  .modal-content {
    width: 800px !important;
    height: 300px;
  }

  .modal-header {
    justify-content: space-between !important;
    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px !important;
    }
    i {
      cursor: pointer;
    }
  }
  .modal-body {
    overflow: auto;
    p {
      font-size: 15px !important;
    }
  }
}

.descriptionModel {
  .modal-content {
    width: 900px !important;
    height: 700px;
    overflow: auto;
  }
}

.input-icon-alignment {
  position: relative;

  i {
    position: absolute;
    top: 14px;
    right: 12px;
  }
}
.custom-input::-webkit-search-cancel-button {
  cursor: pointer;
}

.btn-close {
  display: none;
}
.new-white-boxes {
  position: relative;
  height: 643px;
  width: 613px;
  background-color: #fff;
}

.bulkUploadImageMain {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  h2 {
    color: rgb(28, 0, 54);
    font-size: 26px;
  }
  .dragAndDrop {
    border-radius: 10px;
    background-color: rgb(219, 213, 213, 0.2);
    height: 150px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add box shadow here */
    cursor: pointer;
    p {
      color: rgb(28, 0, 54);
      opacity: 0.5;
    }
    .draganddrop-image-alignment {
      span {
        font-size: 20px;
        color: red;
        display: block;
        margin-top: 56px;
        justify-content: center;
        align-items: center;
      }
    }

    .image-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      img {
        width: 50px;
        height: 50px;
      }
      p {
        display: flex;
        justify-content: center;
      }
    }
  }

  p {
    font-size: 22px;
    max-width: 650px;
    a {
      cursor: pointer;
      font-weight: bold;
    }
  }
  .button-alignment-modal {
    display: flex;
    justify-content: center;
    gap: 10px;

    .cancel-button {
      padding: 10px 40px;
      color: #3f4254;
      font-size: 20px;
      border: none;
      border-radius: 5px;
    }
    .upload-button {
      padding: 10px 40px;
      background-color: #46415d;
      color: white;
      font-size: 20px;
      border: none;
      border-radius: 5px;
    }
  }
  .errors {
    color: red;
    font-size: 18px;
  }
}

.download-model-container {
  .download-model {
    padding: 20px 200px 20px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    .daownload-model-header {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 20px;
      .download-model-header-close {
        padding: 10px;
        cursor: pointer;
        img {
          height: 30px;
          width: 30px;
        }
      }
      p {
        font-size: 26px;
        font-weight: 600;
        margin: 0 !important;
      }
    }
  }
  .select-range-box {
    padding: 40px 20px;
    border-bottom: 1px solid #46415d !important;
    h3 {
      font-size: 26px;
      font-weight: 600;
    }
    input {
      padding: 15px;
      width: 250px;
      height: 40px;
      border-radius: 6px;
      cursor: pointer;
    }
    .date-alignment-flex-design {
      display: flex;
      gap: 90px;

      .date-alignment {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        span {
          display: block;
          padding: 2px;
        }
      }
    }

    .select-range-pera {
      background-color: #46415d !important;
      padding: 10px;
      border-radius: 10px;
      p {
        font-size: 16px;
        color: white;
        font-weight: 400;
        max-width: 490px;
        margin: 0 !important;
      }
    }
  }

  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    button {
      background-color: #46415d;
      padding: 15px 30px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
  .download-model-data {
    p {
      margin: 0px !important;
    }
  }
}
#cell-2-undefined {
  p {
    margin-top: 12px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

hr{
    border: 0;
    height: 1px;
    background: rgb(165, 0, 0);
    background-image: linear-gradient(to right, #ccc, #1C3131, #ccc);
}
.border-aligment{
    border: 1px solid #191235;
    border-radius: 20px;
}
.input-alignment{
    ::placeholder {
        color: #3F4254 !important;
    }
}